import React from 'react';
import PropTypes from 'prop-types';
import NavLink from './NavLink';

const Column = ({ NavLinksData, category, column }) => (
  <ul className='usa-nav__submenu-list'>
    {NavLinksData.filter(
      navLink => navLink.category === category && navLink.column === column
    ).map(navLinkData => (
      <NavLink key={navLinkData.title} navLinkData={navLinkData} />
    ))}
  </ul>
);

const NavLinkList = ({ NavLinksData, category, column }) => (
  <>
    {(() => {
      switch (column) {
        case 'left':
          return (
            <>
              {NavLinksData.length ? (
                <div className='usa-col usa-col-featured-links'>
                  <Column
                    NavLinksData={NavLinksData}
                    category={category}
                    column={column}
                  />
                </div>
              ) : (
                ''
              )}
            </>
          );
        case 'middle':
          return (
            <>
              {NavLinksData.length ? (
                <div className='usa-col usa-col-category-links'>
                  <Column
                    NavLinksData={NavLinksData}
                    category={category}
                    column={column}
                  />
                </div>
              ) : (
                ''
              )}
            </>
          );
        case 'third':
          return (
            <>
              {NavLinksData.length ? (
                <div className='usa-col usa-col-category-links'>
                  <p
                    className='usa-nav__submenu-list margin-bottom-2'
                    style={{ color: 'white' }}
                  >
                    Supported Agencies
                  </p>
                  <Column
                    NavLinksData={NavLinksData}
                    category={category}
                    column={column}
                  />
                </div>
              ) : (
                ''
              )}
            </>
          );
        case 'right':
          return (
            <>
              {NavLinksData.length ? (
                <div className='usa-col usa-default-links'>
                  <Column
                    NavLinksData={NavLinksData}
                    category={category}
                    column={column}
                  />
                </div>
              ) : (
                ''
              )}
            </>
          );
          case 'five':
            return (
              <>
                {NavLinksData.length ? (
                  <div className='usa-col usa-col-category-links'>
                    <p
                      className='usa-nav__submenu-list margin-bottom-2'
                      style={{ color: 'white' }}
                    >
                      The Harding Project
                    </p>
                    <Column
                      NavLinksData={NavLinksData}
                      category={category}
                      column={column}
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            );
        default:
          return null;
      }
    })()}
  </>
);

NavLinkList.propTypes = {
  NavLinksData: PropTypes.array,
  category: PropTypes.string,
  column: PropTypes.string
};

Column.propTypes = {
  NavLinksData: PropTypes.array,
  category: PropTypes.string,
  column: PropTypes.string
};

NavLinkList.defaultProps = {};

export default NavLinkList;
